import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist.</p>
    </Layout>
  );
}
